@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;700&display=swap');


.headbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  font-family: 'Plus Jakarta Sans', sans-serif; 

}
.headbar-title {
  font-size: 24px;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.profile-photo img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

body {
  font-family: 'Plus Jakarta Sans', sans-serif;
}